import https from '../../utils/http'
import Layout from '@/layout/index.vue'
import ParentView from '@/components/ParentView/index.vue';
import router from "@/router";
import {ElMessage} from "element-plus";
import store from "@/store";

const state: any = {
  menuList: [],
  routeMap: [],
  menuLevel: {},
  menuShape: false,
  btnPermissions: [],
  merchantUserList: [],
  rolePermissions: [],
}
const mutations = {
  ADD_MENU: (state: any, device: String) => {
    state.menuList = device
  },
  ADD_SHOW: (state: any, device: Boolean) => {
    state.menuShape = device
  },
  DEL_MENU: (state: any) => {
    state.menuList = []
    state.routeMap = []
    sessionStorage.clear()
  },
  TOGGLE_DEVICE: (state: any, device: String) => {
    state.menuLevel = device
  },
  DEL_TOGGLE_DEVICE: (state: any) => {
    state.menuLevel = {}
  },
  SET_ROUTE_MAP(state:any, routers:any) {
    state.routeMap = routers
    // 为了防止用户刷新页面导致动态创建的路由失效，将其存储在本地中
    sessionStorage.setItem('routeMap', JSON.stringify(routers));
  },
  SET_DYNAMIC_ROUTE_MAP(state:any, routers:any) {
    state.hasGetRoute = true
    let routerMaps = filterRouter(routers)
    // 追加路由
    // 这块是重点，如果直接使用addRoute是无效的
    routerMaps.forEach(item => {
      router.addRoute(item);
    })
    // 最后追加404路由
    router.addRoute({
      path: "/:pathMatch(.*)",
      component: Layout,
      redirect: "/404",
      children: [
        {
          path: '/404',
          name: '404',
          component: () => import('@/views/404.vue'),
          meta: {
            title: '找不到页面',
          }
        },
      ]
    })
  },
  // 设置按钮权限
  SET_BTN_PERMISSIONS: (state: any, btnPermissions: any) => {
    state.btnPermissions = btnPermissions
  },
  SET_MERCHANT_USER_LIST: (state, merchantUserList) => {
    state.merchantUserList = merchantUserList
  },
  SET_ROLE_PERMISSIONS: (state, rolePermissions) => {
    state.rolePermissions = rolePermissions
  },
  DEL_BTN_PERMISSIONS: (state: any) => {
    state.btnPermissions = []
  },
  DEL_ROLE_PERMISSIONS: (state: any) => {
    state.rolePermissions = []
  },
  DEL_MERCHANT_USER_LIST: (state: any) => {
    state.merchantUserList = []
  }
}
const actions = {
  levelDevice(context: any, device: String) {
    context.commit('TOGGLE_DEVICE', device)
  },
  merchantMenuAdd(context: any, device: String) {
    return new Promise((resolve, reject) => {
      // store.getters.menuList
      const routeMap = JSON.parse(sessionStorage.getItem('routeMap'));
      if (routeMap) {
        context.commit('SET_DYNAMIC_ROUTE_MAP', routeMap)
        resolve(true)
      } else {
        https.get("/passport/get-permission-info", {}, false,null).then((res: any) => {
          // menus
          // permissions
          // roles
          // 修改后端返回的数据结构如上
          if (!res || res.menus.length <= 0 || res.roles.length <= 0) {
            ElMessage.error('您没有任何权限，请联系管理员')
            store.dispatch('userMerchant/logout')
            reject(false)
          }
          // 解构赋值
          const {menus, permissions, roles} = res
          // if (res.length <= 0) {
          //   ElMessage.error('您没有任何权限，请联系管理员')
          //   store.dispatch('userMerchant/logout')
          //   reject(false)
          // }
          context.commit('SET_ROUTE_MAP', menus)
          context.commit('SET_DYNAMIC_ROUTE_MAP', menus)
          context.commit('ADD_MENU', menus)
          context.commit('SET_BTN_PERMISSIONS', permissions)
          context.commit('SET_ROLE_PERMISSIONS', roles)
          resolve(true)
        }).catch((err: any) => {
          console.error(err)
        });
      }
    })
  },
  showAdd(context: any, device: Boolean) {
    context.commit('ADD_SHOW', device)
  },
  menuDel(context: any) {
    context.commit('DEL_MENU')
    context.commit('DEL_TOGGLE_DEVICE')
  },
  clearPermission(context: any) {
    context.commit('DEL_BTN_PERMISSIONS')
    context.commit('DEL_ROLE_PERMISSIONS')
  },
  clearMerchantUserList(context: any) {
    context.commit('DEL_MERCHANT_USER_LIST')
  },
  // 获取用户门店列表
  getUserMerchantList(context: any, moduleType: any) {
    return new Promise((resolve, reject) => {
      https.get("/passport/get-user-merchant-list", moduleType, false,null).then((res: any) => {
        context.commit('SET_MERCHANT_USER_LIST', res)
        resolve(res)
      }).catch((err: any) => {
        console.error(err)
      });
    })
  }
}

// 遍历后台传来的路由字符串，转换为组件对象
const filterRouter = (routers) => {
  return routers.filter((router) => {
    // 处理 meta 属性
    router.meta = {
      title: router.name,
      icon: router.icon,
      keepAlive: !router.keepAlive,
    }
    router.hidden = !router.visible
    // 区分布局与视图文件，因为加载方式不同
    if (router.children && router.children.length > 0) {
      router.component = Layout
      if (router.parentId === 0) {
        router.component = Layout
      } else {
        router.component = ParentView
      }
    } else {
      // view
      router.component = loadView(router.component)
    }

    // 判断是否存在子路由，并递归调用自己
    if (router.children && router.children.length) {
      router.children = filterRouter(router.children)
    }
    return true
  })
}
export const loadView = (view) => { // 路由懒加载
  // console.log(`@/views/${view}.vue`)
  if (!view) {
    return;
  }
  return () => import(`../../views/${view}.vue`)
  // return (resolve) => require([`@/views/${view}.vue`], resolve)
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
