import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-733ac6a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-layout" }
const _hoisted_2 = { class: "main-container bj-flex" }
const _hoisted_3 = {
  class: "bj-flexitem-1",
  style: {"min-width":"1100px"}
}
const _hoisted_4 = { class: "bj-flex" }
const _hoisted_5 = { class: "menu-level" }
const _hoisted_6 = { class: "bj-flexitem-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopNavBar = _resolveComponent("TopNavBar")!
  const _component_LeftSideBar = _resolveComponent("LeftSideBar")!
  const _component_tags_view = _resolveComponent("tags-view")!
  const _component_left_level = _resolveComponent("left-level")!
  const _component_app_main = _resolveComponent("app-main")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopNavBar),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LeftSideBar),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createVNode(_component_tags_view)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_left_level)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_app_main, { ref: "appMain" }, null, 512)
          ])
        ])
      ])
    ])
  ]))
}