
import { defineComponent,ref,computed,watch } from 'vue';
import { useStore } from "vuex";
import TopNavBar from "./components/TopNavBar.vue";
import LeftSideBar from "./components/LeftSideBar.vue";
import leftLevel from "./components/leftLevel.vue";
import appMain from "./components/appMain.vue";
import tagsView from "./components/tagsView/tagsView.vue";
export default defineComponent({
    name: 'layout',
    components: {
        TopNavBar,
        LeftSideBar,
        leftLevel,
        appMain,
        tagsView
    },
    setup() {
        const store = useStore();
        const device = computed(() => store.state.app.device);
        return {
            device,
        } 
    },
})
